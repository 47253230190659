const hasUpperlowerCase = val =>
   /^[a-zA-Z0-9!@#$%^&*()\-_=+\\|;:'",.<>/?]*[a-zA-Z][a-zA-Z0-9!@#$%^&*()\-_=+\\|;:'",.<>/?]*$/.test(val); //eslint-disable-line
const hasNumberPhone = val => /^[\d\(\)\-\+]{10,20}$/.test(val); //eslint-disable-line
const hasEmail = (
   val, //eslint-disable-line
) => /^(?!-)(?!.*--)[A-Za-z0-9!#$%&‘*+=?^_`{|}~.-]+(?<!-)(?<!-)@(?!-)(?!.*--)[A-Za-z0-9.-]+(?<!-)(?<!-)$/.test(val); //eslint-disable-line
const hasPassword = val => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*()\-_=+\\|;:'",.<>/?]*$/.test(val); //eslint-disable-line
const hasEightDigitCode = val => /^\d{8}$/.test(val); //eslint-disable-line

export { hasNumberPhone, hasPassword, hasUpperlowerCase, hasEmail, hasEightDigitCode };

// /^[\d()+-]{10,21}$/
